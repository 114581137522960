import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import CustomFooter from "components/Footer/CustomFooter.jsx"
import CustomHeader from "components/Header/CustomHeader.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.jsx"

import image from "assets/img/chili-peppers-circle.jpg"

const useStyles = makeStyles(errorPageStyle)

export default function ErrorPage({ ...rest }) {
  const classes = useStyles()
  return (
    <div id="errorPage">
      <CustomHeader />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        {/* <div className={classes.container}> */}
        <div className={classes.contentCenter}>
          <GridContainer>
            <GridItem md={12}>
              <h1 className={classes.title}>404</h1>
              <h2 className={classes.subTitle}>Page Not Found</h2>
              <h4 className={classes.description}>
                This page either doesn't exist or is currently under
                maintainence.
                <br />
                Please be patient with us as we transition our site to a new
                look and feel.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
        {/* </div> */}
      </div>
      <CustomFooter />
    </div>
  )
}
